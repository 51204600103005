<template>
  <div class="account">
    <div class="userinfo">
      <div class="userinfo__title">Account Info:</div>
      <div class="loader" v-bind:class="{ hide: !isLoading }"></div>
      <div class='userinfo__account__disclosure' v-bind:class="{ hide: isLoading }">Note, all
        prints are charged automatically from your credit card when you finish your session. All credit cards saved by
        default. Please call/text for any issues.</div>
      <div v-bind:class="{ hide: isLoading }">
        <div class="userinfo__account">
          <p>First name: {{ userInfo.first_name }}</p>
          <p>Last name: {{ userInfo.last_name }}</p>
          <p>
            Account status: {{ userInfo.account_status ? "ACTIVE" : "LOCKED" }}
            <img v-if="userInfo.account_status" :src="this.activeimg" alt="" class="userinfo__statusimg" />
            <img v-else :src="this.lockedimg" alt="" class="userinfo__statusimg" />
          </p>
          <div v-if="!userInfo.account_status" class="userinfo__locked">
            <div class="userinfo__locked__text">
              Your account is blocked 🚫 <br />
              Please, contact 718-764-0706 for further assistance.
            </div>
          </div>
          <p>Email: {{ userInfo.email }}</p>
          <p>Username: {{ userInfo.username }}</p>
          <div class="account__buttons__low">
            <button class="account__buttons__logs" @click="redirectToSnacks" :disabled="!userInfo.account_status">
              Buy Snacks
            </button>
            <button class="account__blackfriday" @click="enableCash" v-if="userInfo.username === '7707707707'">
              Toggle cash pay
            </button>
            <button class="account__buttons__logs" @click="redirectToUserLogs">
              Account History
            </button>
          </div>
          <p class="userinfo__account__time" v-if="userInfo.unlimitedUntil != null">
            Time left: {{ days }} days {{ hours }} hours <br />
            {{ minutes }} minutes {{ seconds }} seconds
            <button class="userinfo__account__time__refresh" @click="RefreshPage()">
              Refresh
            </button>
          </p>
          <p class="userinfo__account__time" v-else>
            Time: {{ getHours }} hours {{ getMinutes }} minutes
            <button class="userinfo__account__time__refresh" @click="RefreshPage()">
              Refresh
            </button>
          </p>
          <div class="userinfo__account__credits">
            <div class="userinfo__account__credits__credit"
              :style="{ color: `${userInfo.balance >= 0 ? '#000' : 'red'}` }">
              {{ userInfo.balance >= 0 ? "Credit" : "Balance" }} for prints ${{
        userInfo.balance ? userInfo.balance.toFixed(2) : userInfo.balance
      }}
            </div>
            <div v-if="userInfo.balance < 0" class="userinfo__account__credits__print">
              <div class="userinfo__prints">
                <button @click="redirectToAddPrints()" class="userinfo__prints__button animbtn">
                  Pay For Prints
                </button>
                <div class="userinfo__prints__text">
                  Please, pay balance for prints immediately to avoid any account terminations!
                </div>
              </div>
            </div>
            <div v-else class="userinfo__account__credits__print">
              You can print: <br />
              <p v-for="(print, index) in balanceForEachPrint" :key="print.name">
                {{ print.defaultPrice }} of {{ print.name }}
                {{ index !== balanceForEachPrint.length - 1 ? "or " : "" }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="account__buttons" v-bind:class="{ hide: isLoading }">
      <button class="account__buttons__buyprints" @click="redirectToAddPrints" :disabled="!userInfo.account_status">
        Buy Prints
      </button>
<!--      <div v-if="userInfo.username === '7707707707'">-->
<!--        <button
            class="account__buttons__freespin__on"
            @click="redtospinner()"
            v-if="this.balance < 0 && this.spinner_quantity > 0"
            :disabled="!userInfo.account_status"
        >
          Spin
        </button>
        <button
            class="account__buttons__freespin__off"
            v-else-if="this.balance < 0 && this.spinner_quantity <= 0"
            :disabled="!userInfo.account_status"
        >
          Free Spin
        </button>-->
<!--        <button
            class="account__buttons__freespin__on"
            @click="redtospinner()"
            v-bind:class="{ animbtn: isFreeSpin }"
            :disabled="!userInfo.account_status"
        >
          {{ isFreeSpin ? "Free Spin" : "Spin" }}
        </button>-->
<!--      </div>-->


      <!--      @click="redtospinner()"        -->
      <button
          class="account__buttons__freespin__on"
          @click="redtospinner()"
          :disabled="!userInfo.account_status"
      >
        Spin
      </button>
      <button class="account__blackfriday"
              @click="redirectToAddTime(newOfferName)"
              v-if="this.newOfferName"
              style="position: relative"
              :disabled="!userInfo.account_status">
        <div style="height: 14px"></div>
        {{ newOfferName }}
        <img style="position: absolute; top: -7px; right: 0" width="55px" src="../../../public/new_offer.svg"/>
      </button>


      <button class="account__buttons__buyprints" @click="redirectToAddTime" :disabled="!userInfo.account_status">
        Add Time
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import activeimg from "../../assets/images/dashboard/activeimg.png"
import lockedimg from "../../assets/images/dashboard/lockedimg.webp"

export default {
  name: "UserInfo",
  data() {
    return {
      userInfo: {},
      isLoading: true,
      prints: [],
      activeimg,
      lockedimg,
      days: "0",
      hours: "0",
      minutes: "0",
      seconds: "0",
      int: null,
      newOfferName: "",
    }
  },
  computed: {
    ...mapState(["time"]),
    getHours() {
      return Math.floor(this.time / 60)
    },
    getMinutes() {
      return Math.round((this.time / 60 - this.getHours) * 60).toLocaleString(
        "en-US",
        {
          minimumIntegerDigits: 2,
        }
      )
    },
    balanceForEachPrint() {
      const countingPrints = []
      this.prints.map(print => {
        countingPrints.push({
          name: print.name,
          defaultPrice: (
            +this.userInfo.balance / +print.defaultPrice
          ).toFixed(),
        })
      })
      return countingPrints
    },
  },
  created() {
    this.fetchUserInfo()
    this.fetchPrints()
    this.fetchFreeSpin()
    this.fetchPromoGroup()
    this.$eventBus.$on("updateUser", this.updateUserHandler)
  },
  methods: {
    ...mapActions(["setUserInfo"]),
    enableCash() {
      localStorage.cashAvailable = localStorage.cashAvailable !== "true"
    },
    redirectToUserLogs() {
      this.$router.push({ name: "logs" })
    },
    redirectToSnacks() {
      this.$router.push({ name: "snacks" })
    },
    redirectToAddPrints() {
      this.$router.push({ name: "addprints" })
    },
    redirectToAddTime(preselectName) {
      this.$router.push({
        name: "addtime",
        query: { preselect: preselectName },
      })
    },
    async fetchPromoGroup() {
      try {
        this.isLoading = true
        await this.$axios.get("api/sloffers/promoGroup").then(response => {
          this.newOfferName = response.data.label
        }).finally(() => {
          this.isLoading = false
        })
      } catch (error) {
        console.error(error)
      }
    },
    async fetchFreeSpin() {
      this.loading = true
      try {
        await this.$axios.get("/api/spinner/next_free_date").then(response => {
          this.isFreeSpin = response.data.is_spin
          this.freeSpinDate = response.data.next_spin_date
        }).finally(() => {
          this.loading = false
        })
      } catch (error) {
        console.error(error)
      }
    },
    redtospinner() {
      this.$router.push({ name: "spinner" })
    },
    RefreshPage() {
      this.$router.go(0)
    },
    getUnlimitedHours() {
      this.int = setInterval(() => {
        let untill = new Date(this.userInfo.unlimitedUntil * 1000)
        let now = new Date()
        let dist = new Date(untill - now)
        this.days = Math.floor(dist / (1000 * 60 * 60 * 24))
        this.hours = Math.floor(
          (dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60))
        this.seconds = Math.floor((dist % (1000 * 60)) / 1000)
        if (dist < 0) {
          this.userInfo.unlimitedUntil = null
          clearInterval(this.int)
          this.days = "0"
          this.hours = "0"
          this.minutes = "0"
          this.seconds = "0"
        }
      }, 1000)
    },
    async fetchUserInfo() {
      try {
        this.isLoading = true
        await this.$axios.get("/api/getUser").then(
          response => {
            this.userInfo = response.data
            this.setUserInfo({
              username: this.userInfo.username,
              userId: this.userInfo.user_id,
              cardNumber: this.userInfo.cardNumber,
              time: this.userInfo.time,
              balance: this.userInfo.balance,
              spinner_quantity: this.userInfo.spinner_quantity,
              first_name: this.userInfo.first_name,
              unlimitedUntil: this.userInfo.unlimitedUntil,
            })
            if (this.userInfo.unlimitedUntil != null) this.getUnlimitedHours()
          }
        ).finally(() => {
          this.isLoading = false
        })
      } catch (error) {
        console.error(error)
      }
    },
    async fetchPrints() {
      try {
        this.isLoading = true
        await this.$axios.get(`api/products/printed`).then(response => {
          response.data.map(value =>
            this.prints.push({
              name: value.Name,
              defaultPrice: value.DefaultPrice,
            })
          )
        }).finally(() => {
          this.isLoading = false
        })
      } catch (err) {
        console.error(err)
      }
    },
    updateUserHandler(updatedUserInfo) {
      this.userInfo = updatedUserInfo
    },
    closeDialog() {
      this.dialogBuyPrints = false
    },
  },
}
</script>

<style lang="scss" scoped>
.hide {
  display: none;
}
</style>
