<template>
  <div>
    <div class="svg">
      <div class="svg__left">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          preserveAspectRatio="xMidYMid meet" version="1.0" viewBox="30 30 330 330" zoomAndPan="magnify" role="img"
          aria-label="Wiggly Abstract Shape">
          <g id="__id1346_sxnpkzq3m">
            <path
              d="M3.9,296.5C0,273.7-1,250.4,1,227.3C0.6,151.5,0.3,75.7,4.4,0c101.4,4.2,206.9,3.3,308.4,1.9 c-0.4,20.6-6.2,39.1-18.5,55.8c-4.2,5.7-9,10.9-14.8,14.9c-16.9,11.6-42.3,12.3-52.4,30.2c-8.5,14.9-1.3,33.8-3.5,50.8 c-1.8,14.5-10.6,27.5-22.3,36.2c-11.7,8.7-26,13.4-40.5,15.2c-13,1.6-27.1,1.4-37.6,9.2c-11.9,8.8-15.3,24.7-21.2,38.3 C86.5,288.3,42.8,300.8,3.9,296.5z"
              style="fill: rgb(213, 222, 32)"></path>
          </g>
        </svg>
      </div>
      <div class="svg__right">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          preserveAspectRatio="xMidYMid meet" version="1.0" viewBox="-49.2 -3.7 613.4 702.2" zoomAndPan="magnify"
          style="fill: rgb(0, 0, 0)" role="img" aria-label="Abstract Flat Shape">
          <g id="__id1347_s4dichggko">
            <path clip-rule="evenodd"
              d="M111.1,429.8c10.5-39.5-26-104.6-32.8-144.1 C63.9,201.9,6.4,62.3,102.2,6.4c17.3-10.1,38.4-7.9,54.5,3.6c41.7,29.8,45.5,86.8,77.2,124.8c28.4,34.1,74.5,35.8,111.5,56 c32,17.5,79.5,29,103.6,56.2c115.2,130.1,15.4,221-100.8,320c-44,37.5-72.1,89.5-132.2,105.7C120,698.5-49.2,660,13.6,529.8 C27.3,501.6,100.3,470.5,111.1,429.8L111.1,429.8z"
              fill-rule="evenodd" style="fill: rgb(213, 222, 32)"></path>
          </g>
        </svg>
      </div>
    </div>
    <div class="hours">
      <span>OPEN 24h</span>
      <div class="block24-7">
        <div class="line"></div>
      </div>
      <p>Call us: 718-764-0706</p>
    </div>
    <div class="header">
      <img class="header__logo" @click="redirectToDashboard" :src="img" />
      <button class="header__chatbtn" @click="redirectToChat">
        Chat Support
        <div class="header__chatbtn__circle" v-if="notif"></div>
      </button>
      <ControlLogout />
    </div>

    <UserInfo />
  </div>
</template>

<script>
import img from "../assets/images/design/logo.svg"
import UserInfo from "../components/dashboard/UserInfo"
import ControlLogout from "../components/common/controls/ControlLogout"
import { mapState } from "vuex"
export default {
  name: "Dashboard",
  components: {
    UserInfo,
    ControlLogout,
  },
  data: () => ({
    img,
    notif: false,
    lastmsg: "",
  }),
  created() {
    this.loadConversation(this.isAdmin ? "1" : null)
    
  },
  computed: {
    ...mapState(["balance", "spinner_quantity"]),
  },
  methods: {
    redirectToDashboard() {
      this.$router.go(0)
    },
    redirectToChat() {
      this.$router.push({ name: "chat" })
    },
    
    subscribeToMercure(username) {
      let url = new URL(this.$vars.MERCURE_BASE_URL)
      url.searchParams.append("topic", "/chat/" + username)
      this.eventSource = new EventSource(url, {
        withCredentials: true,
      })
      this.eventSource.onmessage = async () => {
        this.notif = true
      }
    },
    checklast() {
      this.lastmsg = this.chat.slice(-1)
      if (this.lastmsg[0].from === "admin") {
        this.notif = true
      } else {
        if (this.eventSource) {
          this.eventSource.close()
        }
        this.subscribeToMercure(this.username)
      }
    },
    async loadConversation(id) {
      this.chat = []
      try {
        const response = id
          ? await this.$axios.get(`/chat/conv/${id}`)
          : await this.$axios.get("/chat/conv")
        if (response.status === 200) {
          this.firstName = response.data.conversation.user.first_name
          this.username = response.data.conversation.user.username
          response.data.messages.forEach(item => {
            const message = {
              from: item.is_admin
                ? "admin"
                : response.data.conversation.user.first_name,
              msg: item.content,
            }
            this.chat.push(message)
          })
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.checklast()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and (orientation: landscape) {
  .hours {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 100vh;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    background: #fff;

    @media screen and (max-width: 1050px) {
      width: 250px;
    }

    @media screen and (max-width: 890px) {
      display: none;
    }

    span {
      width: 80%;
      height: 100px;
      border-radius: 50px;
      border: solid #000 2px;
      font-size: 30px;
      font-weight: 800;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .block24-7 {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      gap: 15px;
      flex-direction: column;
    }

    .line {
      width: 60%;
      height: 2px;
      background: #000;
    }

    .text {
      width: 70%;
      font-size: 18px;
      line-height: 26px;
      text-align: center;

      @media screen and (max-width: 1050px) {
        width: 80%;
        font-size: 22px;
      }
    }

    p {
      display: block;
      font-size: 24px;
      font-weight: 600;
    }
  }
}

@media screen and (orientation: portrait) {
  .hours {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    background: #fff;
    height: 350px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-height: 1000px) {
      height: 260px;
    }

    @media screen and (max-height: 900px) {
      display: none;
    }

    @media screen and (max-width: 440px) {
      display: none;
    }

    span {
      width: 30%;
      height: 70px;
      border-radius: 50px;
      border: solid #000 2px;
      font-size: 24px;
      font-weight: 800;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-height: 950px) {
        font-size: 18px;
        font-weight: 700;
        width: 25%;
        height: 50px;
      }
    }

    .block24-7 {
      display: flex;
      width: 35%;
      align-items: center;
      justify-content: center;
      gap: 5px;
      flex-direction: column;
      max-width: 270px;

      @media screen and (max-height: 950px) {
        width: 40%;
      }
    }

    .line {
      width: 60%;
      height: 2px;
      background: #000;
    }

    .text {
      width: 100%;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
    }

    p {
      display: block;
      font-size: 22px;
      font-weight: 600;

      @media screen and (max-height: 950px) {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}
</style>
